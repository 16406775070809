@import "@angular/material/theming";

@mixin authentication-dialog-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  // Define any styles affected by the theme.
  .warn {
    color: mat-color($warn);
  }
}

@mixin authentication-dialog-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  // Define any styles affected by the theme.
}

@mixin authentication-dialog-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include authentication-dialog-color($color);
  }
  @if $typography != null {
    @include authentication-dialog-typography($typography);
  }
}
