@import "@angular/material/theming";

@mixin turn-indicator-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  // Define any styles affected by the theme.
  .turn-indicator {
    // Use mat-color to extract individual colors from a palette.
    color: mat-color($accent);
  }
}

@mixin turn-indicator-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .turn-indicator {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}

@mixin turn-indicator-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include turn-indicator-color($color);
  }
  @if $typography != null {
    @include turn-indicator-typography($typography);
  }
}
